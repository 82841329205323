import React, { createContext, useContext, useState } from 'react';

// Create a context for the language
const LanguageContext = createContext();

// Create a provider component that holds the language state
export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ selectedLanguage, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Create a custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

// Create a HOC (Higher Order Component) to provide the language context to class components
export const withLanguageContext = (WrappedComponent) => {
  return class extends React.Component {
    static contextType = LanguageContext;

    render() {
      return <WrappedComponent {...this.props} languageContext={this.context} />;
    }
  };
};
