import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../pages/LanguageContext";
import { useSelector } from "react-redux";

export default function Navbar() {
  let location = useLocation();
  const { selectedLanguage } = useLanguage();

  // Fetch the data directly from the Redux store
  const homeData = useSelector((state) => state.staticData.data) || [];

  const getNameByTargetName = (targetName) => {
    if (!homeData) return null;
    const targetObject = homeData.find(item => item.name === targetName);
    if (!targetObject) return null;

    return selectedLanguage === "en" ? targetObject.value : targetObject.value_ar;
  };

  return (
    <>
      <li className="nav-item">
        <Link
          to="/"
          className={location.pathname === "/" ? "nav-link active" : "nav-link"}
        >
          <span>{getNameByTargetName('HOME')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="packages"
          className={
            location.pathname === "/packages" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('PACKAGES')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="book_addon"
          className={
            location.pathname === "/book_addon" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('ADD_ON')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="services"
          className={
            location.pathname === "/services" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('SERVICES')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="about"
          className={
            location.pathname === "/about" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('ABOUT_US')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="contact-us"
          className={
            location.pathname === "/contact-us" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('CONTACT_US')}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="gallery"
          className={
            location.pathname === "/gallery" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('GALLERY')}</span>
        </Link>
      </li>
      <li className="nav-item no-line">
        <Link
          to="blog"
          className={
            location.pathname === "/blog" ? "nav-link active" : "nav-link"
          }
        >
          <span>{getNameByTargetName('BLOGS')}</span>
        </Link>
      </li>
      <li className="nav-item no-line">
        <Link to="/career" className={
          location.pathname === "/career" ? "nav-link active" : "nav-link"
        }>
          <span>{getNameByTargetName('CAREER')}</span>
        </Link>
      </li>

      <li className="nav-item no-line">
        <Link
          to="packages"
          className="nav-link btn btn-danger rounded btn-xs color-white"
        >
          <span>{getNameByTargetName('BOOK_NOW')}</span>
        </Link>
      </li>
    </>
  );
}
