import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/store';
import { Provider } from 'react-redux';
import Loader from './components/loader/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  return (
    <React.StrictMode>
        <Suspense fallback={<Loader />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
    </React.StrictMode>
  );
};

root.render(<RootComponent />);

reportWebVitals();
